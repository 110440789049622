var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("errorContainer", { attrs: { error: _vm.erreur } }, [
    _c(
      "div",
      { staticClass: "animated fadeIn" },
      [
        _c(
          "b-row",
          [
            _c(
              "b-col",
              { attrs: { md: "12" } },
              [
                _c(
                  "b-card",
                  [
                    _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                      _c(
                        "h2",
                        {
                          staticClass: "text-danger",
                          attrs: { id: "change-password-fields" },
                        },
                        [
                          _vm._v(
                            _vm._s(this.FormMSG(101, "Change my password"))
                          ),
                        ]
                      ),
                    ]),
                    _c(
                      "b-form",
                      { on: { submit: _vm.submitForm } },
                      [
                        _vm.errors.length
                          ? _c("div", [
                              _c("b", [
                                _vm._v(
                                  _vm._s(
                                    this.FormMSG(
                                      100,
                                      "Please correct the following error(s):"
                                    )
                                  ) + " "
                                ),
                              ]),
                              _c(
                                "ul",
                                _vm._l(_vm.errors, function (error) {
                                  return _c("li", [_vm._v(_vm._s(error))])
                                }),
                                0
                              ),
                            ])
                          : _vm._e(),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { sm: "6" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(1, "Old password"),
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      { staticClass: "mb-4" },
                                      [
                                        _c("b-form-input", {
                                          staticClass: "br-none",
                                          attrs: {
                                            type: _vm.showOldPassword
                                              ? "text"
                                              : "password",
                                            id: "oldPassword",
                                            placeholder: this.FormMSG(
                                              2,
                                              "Enter your old password."
                                            ),
                                          },
                                          model: {
                                            value: _vm.oldPassword,
                                            callback: function ($$v) {
                                              _vm.oldPassword = $$v
                                            },
                                            expression: "oldPassword",
                                          },
                                        }),
                                        _c("b-input-group-append", [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn-show-password",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.showOldPassword =
                                                    !_vm.showOldPassword
                                                },
                                              },
                                            },
                                            [
                                              !_vm.showOldPassword
                                                ? _c("Eye", {
                                                    attrs: {
                                                      color: "#9c9d9e",
                                                      size: 16,
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.showOldPassword
                                                ? _c("EyeOff", {
                                                    attrs: {
                                                      color: "#9c9d9e",
                                                      size: 16,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { sm: "6" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(3, "New password"),
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      { staticClass: "mb-4" },
                                      [
                                        _c("b-form-input", {
                                          staticClass: "br-none",
                                          attrs: {
                                            type: _vm.showNewPassword
                                              ? "text"
                                              : "password",
                                            id: "password",
                                            placeholder: this.FormMSG(
                                              4,
                                              "Enter your new password."
                                            ),
                                          },
                                          model: {
                                            value: _vm.password,
                                            callback: function ($$v) {
                                              _vm.password = $$v
                                            },
                                            expression: "password",
                                          },
                                        }),
                                        _c("b-input-group-append", [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn-show-password",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.showNewPassword =
                                                    !_vm.showNewPassword
                                                },
                                              },
                                            },
                                            [
                                              !_vm.showNewPassword
                                                ? _c("Eye", {
                                                    attrs: {
                                                      color: "#9c9d9e",
                                                      size: 16,
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.showNewPassword
                                                ? _c("EyeOff", {
                                                    attrs: {
                                                      color: "#9c9d9e",
                                                      size: 16,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { sm: "6" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(
                                        5,
                                        "New password verification"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      { staticClass: "mb-4" },
                                      [
                                        _c("b-form-input", {
                                          staticClass: "br-none",
                                          attrs: {
                                            type: _vm.showNewPasswordConfirmation
                                              ? "text"
                                              : "password",
                                            id: "passwordverif",
                                            placeholder: _vm.FormMSG(
                                              4,
                                              "Enter your new password."
                                            ),
                                          },
                                          model: {
                                            value: _vm.passwordVerif,
                                            callback: function ($$v) {
                                              _vm.passwordVerif = $$v
                                            },
                                            expression: "passwordVerif",
                                          },
                                        }),
                                        _c("b-input-group-append", [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn-show-password",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.showNewPasswordConfirmation =
                                                    !_vm.showNewPasswordConfirmation
                                                },
                                              },
                                            },
                                            [
                                              !_vm.showNewPasswordConfirmation
                                                ? _c("Eye", {
                                                    attrs: {
                                                      color: "#9c9d9e",
                                                      size: 16,
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.showNewPasswordConfirmation
                                                ? _c("EyeOff", {
                                                    attrs: {
                                                      color: "#9c9d9e",
                                                      size: 16,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { attrs: { slot: "footer" }, slot: "footer" },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  {
                                    attrs: {
                                      cols: _vm.$screen.width < 571 ? 12 : 3,
                                      offset: _vm.$screen.width < 571 ? 0 : 9,
                                    },
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          type: "submit",
                                          variant: "primary",
                                          block: "",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(_vm.FormMSG(102, "Save")) +
                                            "\n\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "b-modal",
          {
            staticClass: "modal-success",
            attrs: {
              "header-class": "header-class-modal-doc-package",
              title: this.FormMSG(104, "Success!"),
              "ok-variant": "success",
              "ok-only": "",
              "modal-class": "mui-animation",
              fade: false,
            },
            on: { ok: _vm.close },
            model: {
              value: _vm.successModal,
              callback: function ($$v) {
                _vm.successModal = $$v
              },
              expression: "successModal",
            },
          },
          [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(this.FormMSG(103, "Your new password has been saved.")) +
                "\n\t\t"
            ),
          ]
        ),
        _c("loading", {
          attrs: { active: _vm.isLoading, "is-full-page": true },
          on: {
            "update:active": function ($event) {
              _vm.isLoading = $event
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }