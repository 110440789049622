<template>
	<errorContainer :error="erreur">
		<div class="animated fadeIn">
			<b-row>
				<b-col md="12">
					<b-card>
						<div slot="header">
							<h2 id="change-password-fields" class="text-danger">{{ this.FormMSG(101, 'Change my password') }}</h2>
						</div>
						<b-form @submit="submitForm">
							<div v-if="errors.length">
								<b>{{ this.FormMSG(100, 'Please correct the following error(s):') }} </b>
								<ul>
									<li v-for="error in errors">{{ error }}</li>
								</ul>
							</div>
							<b-row>
								<b-col sm="6">
									<b-form-group :label="FormMSG(1, 'Old password')">
										<b-input-group class="mb-4">
											<b-form-input
												v-model="oldPassword"
												:type="showOldPassword ? 'text' : 'password'"
												id="oldPassword"
												class="br-none"
												:placeholder="this.FormMSG(2, 'Enter your old password.')"
											/>
											<b-input-group-append>
												<button class="btn-show-password" type="button" @click="showOldPassword = !showOldPassword">
													<Eye v-if="!showOldPassword" color="#9c9d9e" :size="16" />
													<EyeOff v-if="showOldPassword" color="#9c9d9e" :size="16" />
												</button>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col sm="6">
									<b-form-group :label="FormMSG(3, 'New password')">
										<b-input-group class="mb-4">
											<b-form-input
												v-model="password"
												:type="showNewPassword ? 'text' : 'password'"
												id="password"
												class="br-none"
												:placeholder="this.FormMSG(4, 'Enter your new password.')"
											/>
											<b-input-group-append>
												<button class="btn-show-password" type="button" @click="showNewPassword = !showNewPassword">
													<Eye v-if="!showNewPassword" color="#9c9d9e" :size="16" />
													<EyeOff v-if="showNewPassword" color="#9c9d9e" :size="16" />
												</button>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col sm="6">
									<b-form-group :label="FormMSG(5, 'New password verification')">
										<b-input-group class="mb-4">
											<b-form-input
												v-model="passwordVerif"
												:type="showNewPasswordConfirmation ? 'text' : 'password'"
												id="passwordverif"
												:placeholder="FormMSG(4, 'Enter your new password.')"
												class="br-none"
											/>
											<b-input-group-append>
												<button
													class="btn-show-password"
													type="button"
													@click="showNewPasswordConfirmation = !showNewPasswordConfirmation"
												>
													<Eye v-if="!showNewPasswordConfirmation" color="#9c9d9e" :size="16" />
													<EyeOff v-if="showNewPasswordConfirmation" color="#9c9d9e" :size="16" />
												</button>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>

							<div slot="footer">
								<b-row>
									<b-col :cols="$screen.width < 571 ? 12 : 3" :offset="$screen.width < 571 ? 0 : 9">
										<b-button type="submit" variant="primary" block>
											{{ FormMSG(102, 'Save') }}
										</b-button>
									</b-col>
								</b-row>
							</div>
						</b-form>
					</b-card>
				</b-col>
			</b-row>
			<b-modal
				header-class="header-class-modal-doc-package"
				:title="this.FormMSG(104, 'Success!')"
				class="modal-success"
				v-model="successModal"
				@ok="close"
				ok-variant="success"
				ok-only
				modal-class="mui-animation"
				:fade="false"
			>
				{{ this.FormMSG(103, 'Your new password has been saved.') }}
			</b-modal>
			<loading :active.sync="isLoading" :is-full-page="true"></loading>
		</div>
	</errorContainer>
</template>

<script>
import gql from 'graphql-tag';
import { store } from '@/store';
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import languageMessages from '@/mixins/languageMessages';
import { Eye, EyeOff } from 'lucide-vue';

const mutation_UpdMyProfile = gql`
	mutation ($password: String) {
		UpdMyProfile(newProfile: { password: $password })
	}
`;

export default {
	name: 'changePassword',
	mixins: [languageMessages],
	data: function () {
		return {
			// Initialize your apollo data
			erreur: {},
			errors: [],
			oldPassword: '',
			password: '',
			passwordVerif: '',
			successModal: false,
			isLoading: false,

			showOldPassword: false,
			showNewPassword: false,
			showNewPasswordConfirmation: false
		};
	},
	components: {
		Loading,
		Eye,
		EyeOff
	},
	methods: {
		verifyPassword() {
			this.isLoading = true;
			console.log('email:', store.state.myProfile.email);
			console.log('password:', this.oldPassword);
			this.$apollo
				.mutate({
					mutation: gql`
						mutation ($email: String!, $password: String!) {
							VerifyPassword(email: $email, password: $password)
						}
					`,
					variables: {
						email: store.state.myProfile.email,
						password: this.oldPassword
					}
				})
				.then((result) => {
					// onNewToken(this.$apollo.provider.defaultClient, result.data.signIn)
					console.log('result Verify:', result);
					var str = result.data.VerifyPassword;
					if (str == 'OK') {
						this.$apollo
							.mutate({
								mutation: mutation_UpdMyProfile,
								variables: {
									password: this.password
								}
							})
							.then((result) => {
								console.log('after saving new password:', result);
								this.isLoading = false;
								this.successModal = true;
							})
							.catch((error) => {
								this.isLoading = false;
								console.log('error saving new password:', error);
								this.erreur = error;
							});
					} else {
						this.isLoading = false;
						var str = this.FormMSG(231, 'You entered a wrong old password');
						var strTitle = this.FormMSG(232, 'Error');
						//alert(str);
						//console.log("del dep buDe:", buDe);
						this.$bvModal.msgBoxOk(str, {
							title: strTitle,
							size: 'md',
							buttonSize: 'md',
							okVariant: 'success',
							headerClass: 'p-2 border-bottom-0',
							footerClass: 'p-2 border-top-0',
							centered: false
						});
					}

					// console.log(result.data.signIn)
					//  this.$router.push("/myproject")
					//  this.$router.push("/my-dashboard")
					//this.$router.push("/home")
				})
				.catch((error) => {
					//  console.log("unknown error: " + JSON.stringify(error));
					//  return;
					this.isLoading = false;
					if (error.networkError) {
						alert(this.FormMSG(13, 'Your computer does not seem to be connected to the internet.'));
					} else if (error.graphQLErrors.length > 0) {
						var status = error.graphQLErrors[0].status;
						var message = error.graphQLErrors[0].message;
						if (status == 404) {
							alert('Unknown User');
						} else {
							alert('There was an error. Please try again later.');
							console.log('unknown error: ' + JSON.stringify(error));
						}
					}
				});
		},

		close: function () {
			// successModal = false;
			this.$router.push('/profile');
		},
		submitForm: function (e) {
			this.errors = [];

			e.preventDefault();

			if (this.password && this.password != this.passwordVerif) {
				this.errors.push(this.FormMSG(11, 'You must enter your password two identical times.'));
				return true;
			}

			if (this.password.length == 0) {
				this.errors.push(this.FormMSG(12, 'You must enter a new password.'));
				return true;
			}
			this.verifyPassword();
		}
	}
};
</script>
